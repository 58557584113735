@import url("./uplot.css");
@import url("./slick.min.css");
@import url("./slick-theme.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .\?\?\? {
    @apply bg-red-100;
  }
  .\?\?\? > * {
    @apply outline outline-2 outline-red-500/75;
  }
}

html,
body {
  background: #ffffff;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

ul,
ol {
  list-style-position: inside;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

.MuiDataGrid-cell {
  max-height: fit-content !important;
  white-space: initial !important;
}

/* 
 * remove autofill blue background color 
 * https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
 */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/*
 * hide the eye of the input password field in edge, IE
*/
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.unselectable {
  user-drag: none;
  user-select: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

button:focus:not(:focus-visible),
a:focus:not(:focus-visible),
[role="button"]:focus:not(:focus-visible) {
  outline: 0;
}

button:focus:focus-visible,
a:focus:focus-visible,
[role="button"]:focus:focus-visible {
  outline: 2px solid #40b2e4;
}

#article-parser {
  [class*="header2"],
  [class*="header3"] {
    scroll-margin-top: 92px;
  }
}
